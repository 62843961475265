.register_flow_wrapper {
    background-image:url(../img/register_bakcground.png);  
    background-repeat:  no-repeat;                                      
    background-position:center center;                    
    background-size:cover;                               
    width:100%;                                            
    height:100vh; 
}

.register_flow_logo {
    width:280px;
    margin: 0 auto;
}
.register_flow_logo img{
    width:100%;
}
.register_flow_box{
    width: 720px;
    height: 520px;
    margin: 0 auto;
    border: 2px solid #102455;
    border-radius: 10px;
    background-color: white;
}

.intro_h2{
    margin-top: 50px;
    color: #102455;
    padding-left: 60px;
}

.intro_h4{
    color: #102455;
    padding-left: 60px;
    font-weight: normal; 
}

.intro_input {
    margin-top:50px;
    text-align: center;
}

.register_back_button{
    width: 150px;
    height: 45px;
    box-shadow: 0 0 5px 0;
    border: 2px solid #102455;
    border-radius: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    background-color:white;
}

.register_back_button:hover{
    border-bottom: 1px solid#102455;
  }

.register_back_link{
    color: #102455 !important;
    width: 100%;
    text-decoration: none;
    font-weight: bold;
}
.register_button {
    width: 150px;
    height: 45px;
    background-color:#102455;
    box-shadow: 0 0 5px 0;
    border-bottom: 5px solid #091533;
    border-radius: 16px;
    display: flex;
    align-items: center;
    text-align: center;
}

.register_button:hover{
  background: #002D9C;
  border-bottom: 2px solid #012379;
}

.register_link{
    color: #fff !important;
    width: 100%;
    text-decoration: none;
    font-weight: bold;
  }

.update_wrapper{
margin-top: 40px;
}